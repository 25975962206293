import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import VueQuillEditor from "vue-quill-editor"; //引入富文本编译器
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)


import api from '../src/api/api';
Vue.prototype.$api = api 
Vue.prototype.$uploadURL = 'https://my.kidsbrown.cn/api/upload/uploadFile'
Vue.use(VueQuillEditor);
Vue.use(ElementUI);

import ComBtn from '@/components/com-btn.vue'
import NoneImg from '@/components/com-noimg.vue'
Vue.component("ComBtn", ComBtn);
Vue.component("NoneImg", NoneImg);

Vue.filter("dataFormat", function (originVal, args) {
  if (!originVal) {
    return "";
  }
  const dt = new Date(originVal);
  const y = dt.getFullYear();
  const m = (dt.getMonth() + 1 + "").padStart(2, "0");
  const d = (dt.getDate() + "").padStart(2, "0");
  const hh = (dt.getHours() + "").padStart(2, "0");
  const mm = (dt.getMinutes() + "").padStart(2, "0");
  const ss = (dt.getSeconds() + "").padStart(2, "0");
  if (args == "y-m-d") {
    return `${y}-${m}-${d}`;
  } else if (args == "h-m-s") {
    return `${hh}:${mm}:${ss}`;
  } else if (args == "m-d,h-m") {
    return `${m}-${d} ${mm}:${ss}`;
  } else {
    return `${y}-${m}-${d} ${hh}:${mm}:${ss}`;
  }
});


router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
