import instance from './https'

const exercise = {
  getPracticeList (data) {
    return instance({
      url: 'practice/practiceListByCourseCode',
      method: 'post',
      data: data || {}
    })
  },

  getPracticeListLog (data) {
    return instance({
      url: 'memberPracticeRecords/webPracticeList',
      method: 'post',
      data: data || {}
    })
  },

  getQuestionList (data) {
    return instance({
      url: 'practiceQuestion/queryAllList',
      method: 'post',
      data: data || {}
    })
  },

  saveMemberPractice (data) {
    return instance({
      url: 'memberPracticeRecords/addPracticeRecords',
      method: 'post',
      data: data || {}
    })
  },



}





export default exercise