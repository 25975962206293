import instance from "./https";

const home = {
  //家庭学习详情
  getFamilyDetail(data){
    return instance({
      url:'familyLearning/queryOne',
      method:'post',
      data:data || {},
    })
  },
    //家庭学习
    getFamilyList(data){
      return instance({
        url:'familyLearning/list',
        method:'post',
        data:data || {},
      })
    },
  //获得首页配置文件
  getPortalConfig(data){
    return instance({
      url:"examConfig/queryOne",
      method:"post",
      data:data || {},
    });
  },
  //获取音频详情
  getAudioDetail(data){
    return instance({
      url:"audio/queryOne",
      method:"post",
      data:data || {},
    });
  },
  //根据名称获取音频列表
  getAudioListByname(data){
    return instance({
      url:"audio/queryList",
      method:"post",
      data:data || {},
    });
  },
  //获取FM音频分类
  getAudioType1(data) {
    return instance({
      url: "audioType/list",
      method: "post",
      data: data || {},
    });
  },

  //获取playground分类
    getPlaygroundType1(data) {
      return instance({
        url: "playgroundType/list",
        method: "post",
        data: data || {},
      });
    },
  //获取FM音频列表
  getAudioList(data){
    return instance({
      url:"audioType/queryList",
      method:"post",
      data: data || {},
    });
  },
  //根据FM音频分类查询列表
  getAudioListByType(data){
    return instance({
      url:"audio/list",
      method:"post",
      data:data||{},
    });
  },
  //根据名字分类查询活动
  getActivityByName(data) {
    return instance({
      url: "activity/list",
      method: "post",
      data: data || {},
    });
  },
  // 获取轮播图
  getBannerList(data) {
    return instance({
      url: "carousel/list",
      method: "post",
      data: data || {},
    });
  },
  //教学大纲分类
  getPublicList(data) {
    return instance({
      url: "syllabusType/list",
      method: "post",
      data: data || {},
    });
  },
  getSyllabusDetail(data){
    return instance({
      url: "syllabus/queryOne",
      method: "post",
      data: data || {},
    });
  },
  //教学大纲分类
  // getSyllabusTypeList(data) {
  //   return instance({
  //     url: "syllabus/syllabusTypeList",
  //     method: "post",
  //     data: data || {},
  //   });
  // },
  //教学大纲列表
  getSyllabusList(data) {
    return instance({
      url: "syllabus/list",
      method: "post",
      data: data || {},
    });
  },
  //获取活动详情
  getActivityDetail1(data){
    return instance({
      url:"activity/queryOne",
      method:"post",
      data:data || {},
    })
  },
  //获取活动列表
  getActivityList(data){
    return instance({
      url: "activity/list",
      method: "post",
      data: data || {},
    });
  },
 
  getFriendlyList(data) {
    return instance({
      url: "friendlyLink/queryList",
      method: "post",
      data: data || {},
    });
  },

  getIndexFooter(data) {
    return instance({
      url: "systemConfig/queryFooter",
      method: "post",
      data: data || {},
    });
  },
  getEBankList(data) {
    return instance({
      url: "eBank/queryList",
      method: "post",
      data: data || {},
    });
  },

  getBannerDetail(data) {
    return instance({
      url: "banner/queryOne",
      method: "post",
      data: data || {},
    });
  },

  getActivityDetail(data) {
    return instance({
      url: "activity/list",
      method: "post",
      data: data || {},
    });
  },
};

export default home;
