import instance from './https'

const partyBuild = {
  getBabyList(data) {
    return instance({
      url: '/baby/queryAllBabyList',
      method: 'post',
      data: data || {}
    })
  },

  getDetail (data) {
    return instance({
      url: '/baby/queryOneFie',
      method: 'post',
      data: data || {}
    })
  },

  view (data) {
    return instance({
      url: '/baby/update',
      method: 'post',
      data: data || {}
    })
  },


  getMore (data) {
    return instance({
      url: '/baby/saveBaby',
      method: 'post',
      data: data || {}
    })
  },
  likeBaby (data) {
    return instance({
      url: '/babyLike/saveBabyLike',
      method: 'post',
      data: data || {}
    })
  },
  queryBabyCount (data) {
    return instance({
      url: '/babyLike/queryBabyOne',
      method: 'post',
      data: data || {}
    })
  },
}


export default partyBuild
