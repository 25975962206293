<template>
  <div id="app">
    <router-view> </router-view>
  </div>
</template>
<script>
export default {
  methods:{
       //禁用F12
    forbidden(){
       document.onkeydown = function() {
        var e = window.event || arguments[0];
        if (e.keyCode == 123) {
            return false;
        }
    }
    },
  //禁用右键
    forRightClick(){
      document.oncontextmenu = function() {
        return false;
    }
    }
  },
  created() {
    // 在页面加载时读取sessionStorage里的状态信息
    if (localStorage.getItem('navIndex')) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(localStorage.getItem('navIndex'))
        )
      )
    }
    // 在页面刷新时将vuex里的信息保存到localStorage里
    // beforeunload事件在页面刷新时先触发
    window.addEventListener('beforeunload', () => {
      localStorage.setItem('navIndex', JSON.stringify(this.$store.state.nowNavIndex))
    })
    //禁用控制台
      this. forbidden()
      this.forRightClick()
  }
};
</script>
<style lang="less">
@import "./assets/css/common.css";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
body {
  margin: 0;
  padding: 0;
  margin: 0px auto;
  font-size: 14px;
  line-height: 24px;
  color: black;
  width: 100%;
  // width: 100%;
  height: 100%;
  zoom: 1;
  background: #ffffffff;
  font-family: frutigernext, "Microsoft YaHei", Arial, Helvetica, sans-serif,
    Arial, "宋体";
  word-break: break-all;
}
</style>
