import Vue from "vue";
import VueRouter from "vue-router";

const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};

const Index = () => import("../views/index.vue");
const Home = () => import("../views/home/Home.vue");
const TeachClassification = () => import("../views/teach/TeachClassification.vue");
const TeachDetail = () => import("../views/teach/TeachDetail.vue");
const Holiday = () => import("../views/holiday/holiday.vue");
const HolidayDetail = () => import("../views/holiday/holidayDetail.vue");
const Fm = () => import("../views/Fm/fm.vue");
const FmDetail = () => import("../views/Fm/fmDetail.vue");
const Cec = () => import("../views/cec/cec.vue");
const BannerContent = () => import("../views/bannerContent/bannerContent.vue");
const CecTest = () => import("../views/cec/cecTest.vue");
const CecDetail = () => import("../views/cec/cecDetail.vue");
const Curriculum = () => import("../views/curriculum/curriculum.vue");
const CurriculumDetail = () => import("../views/curriculum/curriculumDetail.vue");
const CurriculumCollect = () => import("../views/curriculum/curriculumCollect.vue");
const kidsky = () => import("../views/kidsky/kidsky.vue");
const kidskyDetail = () => import("../views/kidsky/kidskyDetail.vue");
const kidskyCollect = () => import("../views/kidsky/kidskyCollect.vue");
const FamilyLearning = () => import("../views/familyLearning/familyLearning.vue");
const FamilyDetail = () => import("../views/familyLearning/familyDetail.vue");
const BannerDetail = () => import("../views/home/BannerDetail.vue");
const Notice = () => import("../views/notice/Notice.vue");
const NoticeDetail = () => import("../views/notice/NoticeDetail.vue");
const PartyBuilding = () => import("../views/partyBuilding/PartyBuilding.vue");
const BuildingDetail = () => import("../views/partyBuilding/BuildingDetail.vue");
const MoreBuilding = () => import("../views/partyBuilding/MoreBuilding.vue");
const Practice = () => import("../views/practice/Practice.vue");
const PracticeLesson = () => import("../views/practice/PracticeLesson.vue");
const PracticeExercise = () => import("../views/practice/PracticeExercise.vue");
const PracticeExamination = () => import("../views/practice/PracticeExamination.vue");
const ExamIng = () => import("../views/practice/exam/Exam");
const ExamEnd = () => import("../views/practice/exam/ExamEnd");
const ExamResult = () => import("../views/practice/exam/Result");
const WrongBook = () => import("../views/practice/WrongBook");
const DoWrongBook = () => import("../views/practice/doWrongBook/DoWrongBook");
const Login = () => import("../views/login/index.vue");
const Register = () => import("../views/login/Register.vue");
const Forgetpassword = () => import("../views/login/Forgetpassword.vue");
const Agreement = () => import("../views/login/Xieyi.vue");
const Ascension = () => import("../views/ascension/index.vue");
const ImageLesson = () => import("../views/practice/lesson/ImageLesson");
const VideoLesson = () => import("../views/practice/lesson/VideoLesson");
const ExerciseLog = () => import("../views/practice/exercise/Log");
const DoExercise = () => import("../views/practice/exercise/DoExercise");
const ExerciseResult = () => import("../views/practice/exercise/Result");
const Introduce = () => import("../views/introduce/index");
const Opinion = () => import("../views/opinion/index");
const OpinionEnd = () => import("../views/opinion/End");
const User = () => import("../views/user/index");
const ProductDetail = () => import("../views/practice/ProductDetail.vue");
const Cart = () => import("../views/practice/Cart.vue");
const PayOrder = () => import("../views/practice/PayOrder.vue");
const AlipayMiddle = () => import("../views/pay/AlipayMiddle.vue");
const WeiMiddle = () => import("../views/weiPay/WeiMiddle.vue");

Vue.use(VueRouter);

const routes = [

  {
    path: "/",
    name: "index",
    component: Index,
    redirect: "/home",
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
  {
    path: "/forgetpassword",
    name: "Forgetpassword",
    component: Forgetpassword,
  },
  {
    path: "/agreement",
    name: "Agreement",
    component: Agreement,
  },
 
  {
    path: "/alipay/middle",
    name: "alipayMiddle",
    component: AlipayMiddle,
  },
  {
    path: "/weiMiddle",
    name: "weiMiddle",
    component: WeiMiddle,
  },
  {
    path: "/index",
    name: "Index",
    redirect: "/home",
    component: Index,
    children: [
      { path: "/home", component: Home },
      { path: "/teachClassification", component: TeachClassification },
      { path: "/teachDetail", component: TeachDetail },
      { path: "/holiday", component: Holiday },
      { path: "/holidayDetail", component: HolidayDetail },
      { path: "/fm", component: Fm },
      { path: "/fmDetail", component: FmDetail },
      { path: "/cec", component: Cec },
      { path: "/BannerContent", component: BannerContent },
      { path: "/cecTest", component: CecTest },
      { path: "/cecDetail", component: CecDetail },
      { path: "/curriculum", component: Curriculum },
      { path: "/curriculumDetail", component: CurriculumDetail },
      { path: "/curriculumCollect", component: CurriculumCollect },
      { path: "/kidsky", component: kidsky },
      { path: "/kidskyDetail", component: kidskyDetail },
      { path: "/kidskyCollect", component: kidskyCollect },
      { path: "/familyLearning", component: FamilyLearning },
      { path: "/familyDetail", component: FamilyDetail },
      { path: "/bannerDetail", component: BannerDetail },
      { path: "/notice", component: Notice },
      {
        path: "/partyBuilding",
        component: PartyBuilding,
      },
      { path: "/partyBuilding/detail", component: BuildingDetail },
      { path: "/partyBuilding/more", component: MoreBuilding },
      { path: "/practice", component: Practice },
      { path: "/practice/lesson", component: PracticeLesson },
      { path: "/practice/lesson/image", component: ImageLesson },
      { path: "/practice/lesson/video", component: VideoLesson },
      { path: "/practice/exercise", component: PracticeExercise },
      { path: "/practice/exercise/log", component: ExerciseLog },
      { path: "/practice/exercise/DoExercise", component: DoExercise },
      { path: "/practice/exercise/result", component: ExerciseResult },
      { path: "/practice/examination", component: PracticeExamination },
      { path: "/practice/examination/exam", component: ExamIng },
      { path: "/practice/examination/end", component: ExamEnd },
      { path: "/practice/examination/result", component: ExamResult },
      { path: "/notice/detail", component: NoticeDetail },
      { path: "/ascension", component: Ascension },
      { path: "/practice/wrongbook", component: WrongBook },
      { path: "/practice/dowrongbook", component: DoWrongBook },
      { path: "/introduce", component: Introduce },
      { path: "/opinion", component: Opinion },
      { path: "/opinion/end", component: OpinionEnd },
      { path: "/user", component: User },
      { path: "/product/detail", component: ProductDetail },
      { path: "/product/cart", component: Cart },
      { path: "/product/payOrder", component: PayOrder },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

const whiteList = ['/login','/register','/forgetpassword','/agreement','/index','/home','/partyBuilding',
'/','/sockjs-node/info','/cec','/cecDetail','/cecTest','/partyBuilding/detail','/kidsky','/kidskyDetail',"/kidskyCollect",'/fm','/fmDetail','/teachClassification','/teachDetail','/holiday','/holidayDetail']
router.beforeEach((to, from, next) => {
 
  if (whiteList.indexOf(to.path) !== -1) {
    next();
  }  else {
    let isExist = sessionStorage.getItem("userInfo") ? true : false;
    if (!isExist) {
      next("/login");
    } else {
      next();
    }
  }
});

// // 挂载路由导航守卫,to表示将要访问的路径，from表示从哪里来，next是下一个要做的操作 next('/login')强制跳转login
// router.beforeEach((to, from, next) => {

//   if (to.path === '/login' || to.path === '/authorization') {
//     next();
//   } else {
//     let token = "";//token保存在localstorage中
//     let item = localStorage.getItem('token');
//     // 先将拿到的试着进行json转为对象的形式
//     try {
//       item = JSON.parse(item);
//     } catch (error) {
//       // eslint-disable-next-line no-self-assign
//       item = item;
//     }
//     if (item && item.startTime) {
//       let date = new Date().getTime();
//       // 如果大于就是过期了，如果小于或等于就还没过期
//       let timel = date - item.startTime;
//       if (timel < item.expires) {
//         token = item.value;
//       }
//     }
//     if (token === null || token === '') {
//       next('/login');
//     } else {
//       next();
//     }
//   }
// })

export default router;
