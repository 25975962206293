import instance from './https'

const notice = {
  queryMonthList (data) {
    return instance({
      url: 'notice/queryMonthList',
      method: 'post',
      data: data || {}
    })
  },

  


  queryNoticeDetail (data) {
    return instance({
      url: 'notice/queryOne',
      method: 'post',
      data: data || {}
    })
  },
//查询商品分类
  queryTypeList (data) {
    return instance({
      url: 'productType/list',
      method: 'post',
      data: data || {}
    })
  },
  //查询列表
  getList(data) {
    return instance({
      url: 'product/queryChooseList',
      method: 'post',
      data: data || {}
    })
  },

  queryDetail(data) {
    return instance({
      url: 'product/queryOne',
      method: 'post',
      data: data || {}
    })
  },
  getTypeList(data) {
    return instance({
      url: 'productNorm/queryList',
      method: 'post',
      data: data || {}
    })
  },
  addCart(data) {
    return instance({
      url: 'shoppingCart/saveShopping',
      method: 'post',
      data: data || {}
    })
  },

  getAddressList(data) {
    return instance({
      url: 'memberAddress/queryList',
      method: 'post',
      data: data || {}
    })
  },
  
  queryShopping(data) {
    return instance({
      url: 'shoppingCart/queryShoppingCartListPage',
      method: 'post',
      data: data || {}
    })
  },
  deleteShoppingCart(data) {
    return instance({
      url: 'shoppingCart/deleteShoppingCart',
      method: 'post',
      data: data || {}
    })
  },
  reduceShopping(data) {
    return instance({
      url: 'shoppingCart/reduceShoppingCart',
      method: 'post',
      data: data || {}
    })
  },
  addShopping(data) {
    return instance({
      url: 'shoppingCart/increaseShoppingCart',
      method: 'post',
      data: data || {}
    })
  },
  addAddressList(data) {
    return instance({
      url: 'memberAddress/addAddress',
      method: 'post',
      data: data || {}
    })
  },
  addOrder(data) {
    return instance({
      url: 'order/saveOrder',
      method: 'post',
      data: data || {}
    })
  },
  addOrderList(data) {
    return instance({
      url: 'order/saveOrder',
      method: 'post',
      data: data || {}
    })
  },
  querryPayStatus(data) {
    return instance({
      url: '/order/queryOrder',
      method: 'post',
      data: data || {}
    })
  },
  deleteShopping(data) {
    return instance({
      url: 'shoppingCart/deleteShopping',
      method: 'post',
      data: data || {}
    })
  },
  deleteBaby(data) {
    return instance({
      url: 'baby/delete',
      method: 'post',
      data: data || {}
    })
  },
  ProductPictureList (data) {
    return instance({
      url: 'productPicture/queryList',
      method: 'post',
      data: data || {}
    })
  },
  // getAddressList(data) {
  //   return instance({
  //     url: 'agent/queryOne',
  //     method: 'post',
  //     data: data || {}
  //   })
  // },
  getReferralCode(data){
    return instance({
      url: 'agentGarden/queryOne',
      method: 'post',
      data: data || {}
    })
  }
}

export default notice
