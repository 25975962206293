import instance from './https'

const exam = {
  //获取考试列表
  getExamList (data) {
    return instance({
      url: 'exam/queryList',
      method: 'post',
      data: data || {}
    })
  },
  //获取考试子类列表
  getExamSubclassList (data) {
    return instance({
      url: 'examSubclass/queryPartList',
      method: 'post',
      data: data || {}
    })
  },
  //进入考试子类详情页
  getExamDetail(data){
    return instance({
      url: 'examSubclass/queryOne',
      method: 'post',
      data: data || {}
    })
  },

  getPracticeList (data) {
    return instance({
      url: 'practice/weblist',
      method: 'post',
      data: data || {}
    })
  },

  getQuestionList (data) {
    return instance({
      url: 'practiceQuestion/queryList',
      method: 'post',
      data: data || {}
    })
  },

  sumbitExam (data) {
    return instance({
      url: 'memberPracticeRecords/addExamRecords ',
      method: 'post',
      data: data || {}
    })
  },

}

export default exam
