import instance from "./https";

const login = {
  login(data) {
    return instance({
      url: "member/login",
      method: "post",
      data: data || {},
    });
  },
  register(data) {
    return instance({
      url: "member/register",
      method: "post",
      data: data || {},
    });
  },
  getVerify(data) {
    return instance({
      url: "verification/addOne",
      method: "post",
      data: data || {},
    });
  },
  revise(data) {
    return instance({
      url: "member/forgetPassword",
      method: "post",
      data: data || {},
    });
  },
  getForgetVerify(data) {
    return instance({
      url: "verification/addForgetOne",
      method: "post",
      data: data || {},
    });
  },
};
export default login;
