import Vue from "vue";
import Vuex from "vuex";
import { CHANGE_NAVINDEX } from "./mutation-types.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    nowNavIndex: JSON.parse(localStorage.getItem("navIndex")) || 0,
    // memberName: sessionStorage.getItem("userInfo") ? JSON.parse(sessionStorage.getItem("userInfo")).phone : "用户",
    // memberLogo: sessionStorage.getItem("userInfo") ? JSON.parse(sessionStorage.getItem("userInfo")).logo : "",
  },
  mutations: {
    [CHANGE_NAVINDEX](state, payload) {
      localStorage.setItem("navIndex", payload);
      state.nowNavIndex = payload;
    },

    updateMemberName(state, memberName) {
      state.memberName = memberName;
    },

    updateMemberLogo(state, logo) {
      state.memberLogo = logo;
    },
  },
  actions: {},
  modules: {},
});
