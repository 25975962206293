<template>
  <div class="bd">
    <div class="bg" />
    <span style=" color: #c1c4cc;">暂无数据</span>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.bd {
  height: 100%;
  text-align: center;
  .bg {
    margin: 30px auto 0;
    width: 250px;
    height: 250px;
    background: url(../assets/img/com/none.png);
    background-size: cover;
  }
}
</style>