import axios from "axios";
import { Message } from "element-ui";

//根据环境变量 区分默认地址
if (process.env.NODE_ENV === "production") {
  axios.defaults.baseURL = "https://my.kidsbrown.cn/api/";
  // axios.defaults.baseURL = "http://192.168.0.185:8001/api/"
} else if (process.env.NODE_ENV === "dev") {
  axios.defaults.baseURL = "https://my.kidsbrown.cn/api/";
  // axios.defaults.baseURL = "http://192.168.0.185:8001/api/"
} else {
  axios.defaults.baseURL = "https://my.kidsbrown.cn/api/";
  // axios.defaults.baseURL = "http://192.168.0.185:8001/api/";

}

// 设置超时时间和跨域是否允许携带凭证
let instance = axios.create({ timeout: 10000 });
// instance.defaults.withCredentials = true

//post 请求头
instance.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";

//请求拦截 设置token校验等
instance.interceptors.request.use(
  (config) => {
    // let token = localStorage.getItem("token");
    // token && (config.headers.Authorization = token);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//响应拦截器
instance.interceptors.response.use(
  (response) => {
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
    // 否则的话抛出错误
    if (response.data.code === 200) {
      return Promise.resolve(response); // 看具体情况配下
    } else {
      Message.error(response.data.message);
      return Promise.reject(response);
    }
    // return response
  },
  (error) => {
    if (error.response.status) {
      // 有返回结果
      switch (error.response.status) {
        // 401: 未登录
        // 未登录则跳转登录页面，并携带当前页面的路径
        // 在登录成功后返回当前页面，这一步需要在登录页操作。
        case 401: // 未登录
          router.replace({
            path: "/login",
            query: {
              redirect: router.currentRoute.fullPath,
            },
          });
          break;
        // 403  拒绝  token过期
        // 404  网络请求不存在
        case 404: // 未登录
          Message.error("网络请求不存在");
          break;
      }
    } else {
      // 服务器没返回结果
      if (!window.navigator.onLine) {
        //断网
        return;
      }
      return Promise.reject(error);
    }
  }
);

export default instance;
