<template>
  <div>
    <el-button type="danger" :disabled="isDisabled">{{ label }}</el-button>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "点击",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="less" scoped>
.el-button {
  border-radius: 2px;
  padding: 8px 20px;
  font-size: 18px;
}
.el-button--danger {
  color: #fff;
  background-color: #db3b3d;
  border-color: #db3b3d;
}
</style>
