import instance from './https'

const wrongbook = {
  getList (data) {
    return instance({
      url: 'memberPracticeRecordsDetailError/queryNameList',
      method: 'post',
      data: data || {}
    })
  },

  getDetailList (data) {
    return instance({
      url: 'memberPracticeRecordsDetailError/list',
      method: 'post',
      data: data || {}
    })
  },

  reDoWrong (data) {
    return instance({
      url: 'memberPracticeRecordsDetailError/redo',
      method: 'post',
      data: data || {}
    })
  },

}

export default wrongbook