import instance from './https'

const lesson = {
  //用户输入激活码
  setActivationCode(data){
    return instance({
      url:'member/updateActivation',
      method:'post',
      data:data || {},
    })
  },
  //获取章节详情
  getChapterDetail(data){
    return instance({
      url:'courseChapter/queryOne',
      method:'post',
      data:data || {},
    })
  },
  //取消收藏
  cancelCollect(data){
    return instance({
      url:'collect/delete',
      method:'post',
      data:data || {},
    })
  },
  //查询课程的收藏数和该用户有没有收藏
  getCollectCount(data){
    return instance({
      url:'collect/queryCollectCount',
      method:'post',
      data:data || {},
    })
  },

  //查询课程的点赞数和该用户有没有点赞
  getFavoriteCount(data){
    return instance({
      url:'like/queryLikeCount',
      method:'post',
      data:data || {},
    })
  },
  //点赞
  setFavorite(data){
    return instance({
      url:'like/likeChange',
      method:'post',
      data:data || {},
    })
  },
  //收藏
  setCollect(data){
    return instance({
      url:'collect/collectChange',
      method:'post',
      data:data || {},
    })
    

  },
  //课程详情
  getCourseDetail(data){
    return instance({
      url:'course/queryCourseDetailList',
      method:'post',
      data:data || {},
    })
  },
  //课程类型
  getCourseTypeList(data){
    return instance({
      url:'courseType/list',
      method:'post',
      data:data || {},
    })
  },
  //儿童乐园详情
    getPlaygroundDetail(data){
      return instance({
        url:'playground/queryPlaygroundDetailList',
        method:'post',
        data:data || {},
      })
    },
  //儿童乐园类型
  getPlaygroundTypeList(data){
    return instance({
      url:'playgroundType/list',
      method:'post',
      data:data || {},
    })
  },
  //儿童乐园列表
  getPlaygroundList(data){
    return instance({
      url:'playground/list',
      method:'post',
      data:data || {},
    })
  },

  //课程列表
  getCourseList(data){
    return instance({
      url:'course/list',
      method:'post',
      data:data || {},
    })
  },
  getCategory1 (data) {
    return instance({
      url: 'courseCategory/queryLevel1List',
      method: 'post',
      data: data || {}
    })
  },

  getCategory2 (data) {
    return instance({
      url: 'courseCategory/queryLevel2List',
      method: 'post',
      data: data || {}
    })
  },

  getList (data) {
    return instance({
      url: 'course/webList',
      method: 'post',
      data: data || {}
    })
  },

  queryOneCourse (data) {
    return instance({
      url: 'course/queryOne',
      method: 'post',
      data: data || {}
    })
  },


  getComments (data) {
    return instance({
      url: 'courseComment/list',
      method: 'post',
      data: data || {}
    })
  },

  //  弹幕
  handleComment (data) {
    return instance({
      url: 'courseComment/addComment',
      method: 'post',
      data: data || {}
    })
  },

  handleComments (data) {
    return instance({
      url: 'courseComment/weblist',
      method: 'post',
      data: data || {}
    })
  },

  commentUp (data) {
    return instance({
      url: 'courseCommentUp/up',
      method: 'post',
      data: data || {}
    })
  },



  handleCourseUp (data) {
    return instance({
      url: 'courseUp/up',
      method: 'post',
      data: data || {}
    })
  },

  getVideoIsup (data) {
    return instance({
      url: 'courseUp/queryIsUp',
      method: 'post',
      data: data || {}
    })
  },

  getVideoSections (data) {
    return instance({
      url: 'courseChapter/queryList',
      method: 'post',
      data: data || {}
    })
  },

  addCollect (data) {
    return instance({
      url: 'courseCollect/addCollect',
      method: 'post',
      data: data || {}
    })
  },




}

export default lesson
