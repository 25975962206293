import instance from './https'

const user = {
  getCourseList (data) {
    return instance({
      url: 'courseLearn/list',
      method: 'post',
      data: data || {}
    })
  },

  getExamList (data) {
    return instance({
      url: 'memberPracticeRecords/examList',
      method: 'post',
      data: data || {}
    })
  },
  getCollectList (data) {
    return instance({
      url: 'collect/list',
      method: 'post',
      data: data || {}
    })
  },
  getPracticeList (data) {
    return instance({
      url: 'course/mylist',
      method: 'post',
      data: data || {}
    })
  },
  queryPlatformIntroduce (data) {
    return instance({
      url: 'systemConfig/queryPlatformIntroduce',
      method: 'post',
      data: data || {}
    })
  },

  saveOpinion (data) {
    return instance({
      url: 'opinion/add',
      method: 'post',
      data: data || {}
    })
  },

  getSpecialList (data) {
    return instance({
      url: 'specialPromotion/queryList',
      method: 'post',
      data: data || {}
    })
  },

  getUserInfo (data) {
    return instance({
      url: 'member/queryOne',
      method: 'post',
      data: data || {}
    })
  },

  updateUserInfo (data) {
    return instance({
      url: 'member/update',
      method: 'post',
      data: data || {}
    })
  },


  updatePassword (data) {
    return instance({
      url: 'member/updatePassword',
      method: 'post',
      data: data || {}
    })
  },
  goMyOrder (data) {
    return instance({
      url: 'order/queryMyOrderList',
      method: 'post',
      data: data || {}
    })
  },
  updataActivation (data) {
    return instance({
      url: 'member/updateActivation',
      method: 'post',
      data: data || {}
    })
  },
  addAddress (data) {
    return instance({
      url: 'memberAddress/addAddress',
      method: 'post',
      data: data || {}
    })
  },
  updateAddress(data){
    return instance({
      url:'memberAddress/addAddress',
      method:'post',
      data:data||{}
    })
  },
  deleteAddress (data) {
    return instance({
      url: 'memberAddress/delete',
      method: 'post',
      data: data || {}
    })
  },
  goLogisticsInfo (data) {
    return instance({
      url: 'ExpressMail/queryExpress',
      method: 'post',
      data: data || {}
    })
  },
  refundOrder (data) {
    return instance({
      url: 'orderRefund/refundOrder',
      method: 'post',
      data: data || {}
    })
  },
  updateOrder(data) {
    return instance({
      url: 'order/update',
      method: 'post',
      data: data || {}
    })
  },
}

export default user
