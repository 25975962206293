// 统一处理

import home from './home'
import login from './login'
import partyBuild from './partyBuild'
import lesson from './lesson'
import exam from './exam'
import exercise from './exercise'
import user from './user'
import wBook from './wrongBook'
import notice from './notice'

export default {
  home,
  login,
  partyBuild,
  lesson,
  exam,
  exercise,
  user,
  notice,
  wBook
}
